.LSideBar {
    height: 100%;
    /* background-color: #e0e0e0; */
    display: flex;
    flex-direction: column;
    overflow: hidden; /* 修改这行 */
    box-sizing: border-box; /* 添加这行确保padding不会增加总宽度 */
    transition: width 0.3s ease; /* 添加这行实现平滑过渡 */
}
  
.sidebar-title {
    padding: 10px;
    height: 40px;
    margin: 0;
    background-color: #d0d0d0;
    text-align: center;
}

.conversation-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    flex: 1;
    scrollbar-width: none; /* 添加这行 */
    -ms-overflow-style: none; /* 添加这行 */
}

.conversation-list li {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 10px;
    background-color: #F0F0F0;
    /* border-bottom: 1px solid #ccc; */
}

.conversation-list li .icon {
    margin-right: 10px;
}

.conversation-list::-webkit-scrollbar {
    display: none; /* 添加这个新的规则 */
}