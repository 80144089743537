.Main {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    scrollbar-width: none; /* 添加这行 */
    -ms-overflow-style: none; /* 添加这行 */
}

.Main::-webkit-scrollbar {
    display: none; /* 添加这个新的规则 */
}

.chat-message {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    white-space: pre-wrap; /* 添加这个属性 */
}

.chat-message pre {
    white-space: pre-wrap; /* 添加这个属性 */
}

.system {
    background-color: #f1f1f1c6;
}

.error {
    background-color: #fc7979c6;
    color: white;
}

.user {
    background-color: #afd8ff;
    text-align: right;
}

.assistant {
    background-color: #bcfff2;
}