.RSideBar {
    width: 100%; /* 修改为100%以填充整个容器 */
    height: 100%; /* 添加高度100%以填充整个容器 */
    background-color: #F0F0F0;
    border-left: 1px solid #ccc;
    padding: 10px;
    overflow: hidden; /* 修改这行 */
    box-sizing: border-box; /* 添加这行确保padding不会增加总宽度 */
    transition: width 0.3s ease; /* 添加这行实现平滑过渡 */
}

.RSideBar ul {
    list-style-type: none;
    padding: 0;
    overflow-y: auto;
    scrollbar-width: none; /* 添加这行 */
    -ms-overflow-style: none; /* 添加这行 */
    height: 100%; /* 添加这行确保滚动正常工作 */
}

.RSideBar li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.RSideBar li .icon {
    margin-right: 5px;
}

.RSideBar ul::-webkit-scrollbar {
    display: none; /* 添加这个新的规则 */
}