.ToolBar {
    height: 30px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ToolBarLeft, .ToolBarRight {
    display: flex;
    align-items: center;
    height: 30px;
}

button {
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.ToolBarLeft button {
    margin-left: 10px;
}

.ToolBarRight button {
    margin-right: 10px;
}
    
/* .send-button {
    background-color: #4CAF50;
    color: white;
} */

