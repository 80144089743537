.resizer {
    position: absolute;
    z-index: 1;
  }
  
  .resizer.horizontal {
    width: 1px;
    height: 100%;
    cursor: ew-resize;
    top: 0;
  }
  
  .resizer.horizontal.right {
    right: 0;
  }
  
  .resizer.horizontal.left {
    left: 0;
  }
  
  .resizer.vertical {
    width: 100%;
    height: 1px;
    cursor: ns-resize;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ccc; /* 添加一个背景色使其可见 */
    z-index: 100; /* 确保它在其他元素之上 */
  }
  
  .resizer.vertical.bottom {
    bottom: 0;
  }
  
  .resizer.vertical.top {
    top: 0;
  }
  
  .resizer.vertical:hover {
    background-color: #999; /* 鼠标悬停时改变颜色 */
  }