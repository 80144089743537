.App {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.left-panel {
  min-width: 250px;
  max-width: 50vw;
  transition: width 0.3s;
  position: relative;
}

.left-panel.hide {
  width: 0 !important;
  display: none;
}

.resizer {
  width: 5px;
  height: 100%;
  background-color: #ccc;
  position: absolute;
  right: 0;
  top: 0;
  cursor: ew-resize;
}

.right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.right-sidebar-container {
  position: relative;
  min-width: 300px;
  max-width: 50vw;
  transition: width 0.3s;
  background-color: #e0e0e0; /* 添加这行，与RSideBar的背景色相同 */
  overflow: visible; /* 添加这行 */
  z-index: 10; /* 添加这行 */
}

.footer-container {
  position: relative;
  min-height: 200px;
  max-height: 50vh;
  transition: height 0.3s;
  background-color: #f0f0f0; /* 添加背景色 */
  overflow: visible; /* 添加这行 */
  z-index: 10; /* 添加这行 */
}

/* 确保Footer不会被其他元素覆盖 */
.Footer {
  position: relative;
  z-index: 11;
}


@media (max-width: 768px) {
  .left-panel, .right-sidebar-container {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background-color: white;
  }

  .left-panel {
    left: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .left-panel.show {
    transform: translateX(0);
  }

  .right-sidebar-container {
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .right-sidebar-container.show {
    transform: translateX(0);
  }
}

