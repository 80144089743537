.InputArea {
    flex: 1;
    /* padding: 5px; */
    display: flex;
}

.InputArea textarea {
    width: 100%;
    height: 100%;
    resize: none;
    border: 1px solid #ccc;
    border: none;
    padding: 10px;
    font-size: 16px;
}

.InputArea textarea:focus {
    border: none;
    outline: none; /* 也可以移除默认的 outline */
}