.Header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ccc;
}
  
.HeaderLeft, .HeaderRight {
    width: 40px; /* 或者根据需要调整 */
    display: flex;
    justify-content: center;
    align-items: center;
}

.HeaderCenter {
    flex: 1;
    text-align: center;
    font-weight: bold;
}

.Header button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.icon {
    width: 24px;
    height: 24px;
}